<template>
  <div id="app">
    <RouterView></RouterView>
  </div>
</template>

<script>
import { RouterView } from 'vue-router';
export default {
  name: 'App',
  components: {
    RouterView,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h2{
  cursor: pointer;
  font-family: "DIN-Bold";
}
p{
  font-family: "SFUIText-Regular";
}
.DIN-Bold{
  font-family: "DIN-Bold";
}
.SFUITEXT-REGULAR{
  font-family: "SFUITEXT-REGULAR";
}
</style>
