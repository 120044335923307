// router.js
import { createRouter, createWebHistory } from 'vue-router';
 
 
// 创建router实例
const router = createRouter({
  history: createWebHistory(),
  routes:[
    { path: '/home',name:"home", component: ()=>{return import("@/view/homePage.vue")} },
    { path: '/concept',name:"concept", component: ()=>{return import("@/view/conceptPage.vue")} },
    { path: '/company',name:"company", component: ()=>{return import("@/view/companyPage.vue")} },
    { path: '/contact',name:"contact", component: ()=>{return import("@/view/contactPage.vue")} },
    { path: '/privacy',name:"privacy", component: ()=>{return import("@/view/privacyPage.vue")} },
    { path: '/terms',name:"terms", component: ()=>{return import("@/view/termsPage.vue")} },
    { path: '/',redirect:"/home" }
  ]
});
 
export default router;